@import url("https://fonts.cdnfonts.com/css/campton");

/* CSS Variables */
:root {
  --pry-color: #1560bd;
  --sec-color: #e1efff;
  --loader-bg: #e1efff3f;
  --green-color: #498f00;
  --red-color: #bd1515;
  --yellow-color: #e1a900;
  --dark-color: #000;
  --dark-bold-color: #1c1c1c;
  --light-color: #fff;
  --light-bold-color: #0000000d;
  --grey-color: #d9d9d9;
  --grey-bold-color: #7f7e7f;

  --home-pry: #082552;
  --home-grey: #F9F9F9;
  --home-grey-bold: #3C3C3C;
  --receipt-bg: #ffffffe6;
  
  --bottom-link-color: #13509bcd;
  --nav-color: #6faaf6;
  
}
/* CSS Variables */

/* Dark Mode Variables */
[data-theme="dark"] {
  --pry-color: #f1f6fd;
  --sec-color: #0f1e42;
  --loader-bg: #e1efff3f;
  --green-color: #72dd00;
  --red-color: #fe413b;
  --yellow-color: #ffc107;
  --dark-color: #f0f0f0;
  --dark-bold-color: #c6dcf8;
  --light-color: #0a132a;
  --light-bold-color: #323d58;
  --grey-color: #112641;
  --grey-bold-color: #ccdef5;
  --receipt-bg: #000000dc;
  
  --home-pry: #f1f6fd;
  --home-grey: #f9f9f917;
  --home-grey-bold: #F9F9F9;

  --bottom-link-color: #e1efffd5;
  --nav-color: #b7bbc2;

}
/* Dark Mode Variables */